/* mobile */
@media (min-width:279px) and (max-width:480px) and (orientation:portrait) {

    @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500;600&display=swap');

    .resMenu {
        display: block !important;
    }

    .resMenu_web {
        display: none;
    }

    .headerStatic {
        background-color: #fff;
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999999;
    }

    .homeaboutUsmoBi {
        margin-top: 10%;
    }

    .containerpies {
        width: 100%;
        padding-right: 5px !important;
        padding-left: 5px !important;
        margin-right: auto;
        margin-left: auto;
    }

    .ourProdhead {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: bold;
        font-size: 28px;
        line-height: 48px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        text-transform: capitalize;
        color: #252525;
        text-decoration: underline;
    }

    .prodOurHeadr {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 43px;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #004AA3;
    }


    .wwelcome {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        text-transform: capitalize;
        color: #FFFFFF;
        text-align: center;
    }


    .Bigblur {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 26px;
        line-height: 35px;
        letter-spacing: 0.005em;
        text-transform: capitalize;
        color: #FFFFFF;
        text-align: center;
    }


    .smallBlur {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        text-transform: capitalize;
        color: rgba(228, 228, 228, 0.8);
    }


    .blur-button {
        width: 163px;
        height: 42px;
        background: #004AA3;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #FFFFFF;
        padding: 0px 20px;
        border-radius: 37px;
        cursor: pointer;
    }


    .paddingblur {
        padding: 7% 5%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
    }


    .demoHomeCssweb {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }


    .demoBlur {
        background: url(./assests/HO@.jpg);
        height: auto;
    }

    .demoStext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 28px;
        text-align: center;
        letter-spacing: 0.005em;
        text-transform: capitalize;
        color: #FFFFFF;
        width: 100%;
    }

    .demoFtext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        text-transform: capitalize;
        color: #FFFFFF;
        width: 100%;
        text-align: center;
    }

    .paddingDemofircoL {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: 10px;
    }


    .demoTtext {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        text-transform: capitalize;
        color: #FFFFFF;
        width: 100%;
        text-align: center;
    }

    .paddingDemosecCoL {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }


    .cardSecWitheight {
        width: auto !important;
        height: 447px;
        background: rgba(255, 255, 255, 0.25);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .softFooter {
        box-sizing: border-box;
        height: auto;
        background: #F4F4F4;
    }


    .footerBody {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 10px 0px;
        gap: 10px;
    }


    .RowFootsoft {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 30px;
        padding: 0px 10px;
        margin-top: 5%;
    }


    .FirstcOlsoftheaD {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.005em;
        color: #252525;
    }


    .FirstcOlsoftsuB {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #585858;
    }


    .FirstcOlsoftsuBa {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.005em;
        color: #454444;

    }

    .FirstcOlsoftsuBm {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0.005em;
        color: #585858;
    }


    .footfoOT {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px 10px;
    }


    .HaboutusWord {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #004AA3;
    }

    .hAboutTextbig {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 18px !important;
        line-height: 26px !important;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        color: #252525;
    }


    .smallaboutH {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px !important;
        line-height: 22px !important;
        color: #585858;
    }


    .InputCss {
        box-sizing: border-box;
        width: 100%;
        background: #FFFFFF;
        border-radius: 24px;
        padding: 10px 15px;
        border: none;
    }


    .ButtonCss {
        box-sizing: border-box;
        width: 100%;
        background: #003677;
        border-radius: 24px;
        padding: 10px 15px;
        border: none;
        color: #FFFFFF;
        font-weight: 500;
        font-family: 'Poppins';
        font-style: normal;
        font-size: 16px;
    }

    .widthmobifoot {
        width: 60px;
    }

    .footTitltlSoft {
        font-style: normal;
        font-weight: 700;
        font-size: 16.2462px;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-transform: uppercase;
        color: #113C66;
    }

    /* srithar */



    .abouTilr {
        font-weight: 600;
        font-size: 38px;
        line-height: 0px;
        padding-top: 133px;
        padding-left: 20px;
    }

    .aboutbanner {
        /* background: url('./assests/AbCo.jpg'); */
        height: 275px;
        margin-top: 88px;
    }

    .contactGetTit {
        font-weight: 600;
        font-size: 28px;
    }

    .FirstcOlsoftsuB {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
    }

    .FirstcOlsoftsuBa {
        font-weight: 500;
        font-size: 14px;
    }


    .contactMapbetween {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .mapouter {
        width: 100%;
        padding-top: 30px;
    }


    .abouTilr {
        font-size: 20px;
        padding-top: 50px;
    }

    .aboutbanner {
        height: 100px;
    }

    .aboyuiohead {
        font-size: 12px;
    }

    .aboyuioheadMian {
        font-size: 20px;
        line-height: 30px;
    }

    .aboyuioheadMianSecf {
        font-size: 12px;
        line-height: 22px;
    }

    .aboLeadertitle {
        font-size: 18px;
    }

    .widhtLeadrtiou {
        width: 100%;
    }

    .Column_chanage {

        flex-direction: column;
    }

    .leadercontentaboU {
        font-size: 12px;
        line-height: 22px;
    }

    .contianer {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        padding-left: auto !important;
        padding-right: auto !important;
    }

    .Photofix {
        width: 70px;
        height: 115px;
    }

    .kamsTitlE {
        font-size: 20px;
        line-height: 75px;
    }

    .kamsTitlE2 {
        font-size: 14px;
        line-height: 40px;
    }

    .kamsTitlE3 {
        font-size: 10px;
        line-height: 50px;
    }

    .Mtop {
        height: 195px;
    }

    .kamSPADD {
        padding-top: 5%;
    }

    .CS_Font {
        font-size: 18px;
        gap: 10px;
    }

    .Back_Font {
        font-size: 16px;
    }

    .kamsBaneerPic {
        height: 145px;
        margin-top: 72px;
    }

    .kafeauTEr {
        font-size: 18px;
        line-height: 26px;
        justify-content: flex-start;
        padding-left: 10px;
    }

    .kafeauTEr2 {
        font-size: 12px;
        line-height: 22px;
        padding-left: 10px;
    }


    .masatamana {
        height: auto;
        background: #F3F9FF;
        border-radius: 12px;
        margin-top: 5%;
    }

    .widthmasterImageandContent {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masterkAms_title {
        font-size: 16px;
        line-height: 24px;
    }

    .masterkAms_content {
        font-size: 12px;
        line-height: 22px;
    }

    .masatamana2 {
        height: auto;
    }

    .widthmasterImageandContent2 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masterkAms_content2 {
        font-size: 12px;
        line-height: 22px;
    }

    .masatamana3 {
        height: auto;
    }

    .widthmasterImageandContent3 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masatamana4 {
        height: auto;
    }

    .widthmasterImageandContent4 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masterkAms_content2 {
        font-size: 12px;
        line-height: 22px;
        padding-left: 20px;
    }

    .masterkAms_title2 {
        font-size: 16px;
        line-height: 24px;
        padding-left: 20px;
    }

    .masatamana5 {
        height: auto;
    }

    .masterkAms_title {
        font-size: 16px;
        line-height: 24px;
    }

    .widthmasterImageandContent5 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masterkAms_content {
        font-size: 12px;
        line-height: 22px;
    }

    .masatamana5 {
        height: auto;
    }

    .widthmasterImageandContent6 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masatamana6 {
        height: auto;
    }

    .masatamana7 {
        height: auto;
    }

    .widthmasterImageandContent7 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masatamana8 {
        height: auto;
    }

    .widthmasterImageandContent8 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }


    .masatamana9 {
        height: auto;
    }


    .widthmasterImageandContent9 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masatamana10 {
        height: auto;
    }


    .widthmasterImageandContent10 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    .masatamana11 {
        height: auto;
    }


    .widthmasterImageandContent11 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }


    .masatamana12 {
        height: auto;
    }


    .widthmasterImageandContent12 {
        width: 100%;
        height: 200px;
        padding-right: 107px;
    }

    /* srithar */


}

/* mobile */


/* desktop */
@media (min-width:769px) {
    .resMenu {
        display: none;
    }

    .resMenu_web {
        display: block;
    }


    .headerStatic {
        position: fixed;
        width: 100%;
        top: 0px;
        z-index: 9999999;
    }
}

/* desktop */