.card {
    overflow: hidden;
    position: fixed;
    left: 20%;
    top: 10%;
    border-radius: 0.5rem;
    max-width: 470px;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    background-color: #fff;
    z-index: 999999;
}

.dismiss {
    border: none;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 2px 9px;
}


.header {
    padding: 1.25rem 0 1rem 0;
    background-color: #ffffff;
}

@keyframes animate {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.09);
    }
}

.headrsoft {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #ffffff;
    align-items: center;
    padding: 5px 9.5% 10px 9.5%;
    z-index: 9999999;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.logoheader {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #113C66;
}

.headerLi {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 35px;
}

.heaerNavs {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #252525;
    margin-top: 35px;
    cursor: pointer;
    text-decoration: none;
}

.dropTextHead {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #252525;
    cursor: pointer;
    width: 100%;
    padding: 0px 5px;
    border-bottom: 1px solid #d5d2d2;
}

.dropTextHead :hover {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.dropTextHeadmain {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header-button {
    background: #004AA3;
    border-radius: 37px;
    width: 163px;
    height: 42px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 0px 20px;
    border: none !important;
}