@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.homefirst {
    margin-top: 78px;
    background: url('../assests/HO.jpg');
}

.wwelcome {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.AboutBtn {
    box-sizing: border-box;
    width: 148px;
    height: 42px;
    background: #004AA3;
    border-radius: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #FFFFFF;
    cursor: pointer;
}

/* 
.bklur {
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    height: 600px;
} */

.paddingblur {
    padding: 7% 5%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.Bigblur {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    text-transform: capitalize;
    color: #FFFFFF;
}

.smallBlur {
    text-transform: capitalize;
    color: #FFFFFF;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

.blur-button {
    width: 163px;
    height: 42px;
    background: #004AA3;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    padding: 0px 20px;
    border-radius: 37px;
    cursor: pointer;
}

.HaboutusWord {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #004AA3;
}

.hAboutTextbig {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #252525;
}

.gapFix {
    gap: 14%;
}

.smallaboutH {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #585858;
}

.ourProdhead {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    font-size: 46px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
    color: #252525;
}

.ourProTextA {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #585858;
    width: 350px;
}

.prodOurHeadr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #004AA3;
}

.ouProdsEconR {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: rgba(0, 74, 163, 0.8);
}

.ouRbtnReadMoe {
    box-sizing: border-box;
    width: 120px;
    height: 36px;
    background: rgba(0, 74, 163, 0.06);
    border-radius: 37px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #004AA3;
    cursor: pointer;
}

.demoBlur {
    background: url(../assests/HO@.jpg);
    height: 547px;
}


.demoFtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.demoStext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 60px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.demoTtext {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    text-transform: capitalize;
    color: #FFFFFF;
    width: 50%;
}

.cardSecWitheight {
    width: 372px;
    height: 447px;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 15px;
}

.paddingDemofircoL {
    padding: 8% 0;
}

.paddingDemosecCoL {
    padding: 4% 0;
}

.secColdemoLabel {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 54px;
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.InputDemoSec {
    width: 320px;
    height: 44px;
    border: none !important;
    border-radius: 24px;
    font-size: 14px !important;
}

.css-13cymwt-control {
    width: 320px !important;
    height: 44px !important;
    border: none !important;
    border-radius: 24px !important;
}

.demoFormbTn {
    box-sizing: border-box;
    width: 320px;
    height: 44px;
    background: #003677;
    border-radius: 24px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;

}

.demoHomeCssweb {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}