.softFooter {
    box-sizing: border-box;
    /* width: 1440px; */
    height: 476px;
    background: #F4F4F4;
}

.footTitltlSoft {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.005em;
    text-transform: uppercase;
    color: #113C66;
}

.footerBody {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 30px;
    gap: 10px;
}

.RowFootsoft {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cOlsoft {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.FirstcOlsoftheaD {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.005em;
    color: #252525;
}

.FirstcOlsoftsuB {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #585858;
    cursor: pointer;
    width: 100%;
}

.FirstcOlsoftsuBa {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    color: #585858;
}

.footfoOT {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px;
}

.footfoOTteXt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.005em;
    color: #454444;
    opacity: 0.5;
}