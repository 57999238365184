@import url('https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

.headerStatic {
    position: fixed;
    width: 100%;
    top: 0px;
    z-index: 9999999;
}

/* contact */

.aboutbanner {
    background: url('./assests/AbCo.jpg');
    height: 400px;
    margin-top: 88px;
}

/* 
.aboutBannerBlur {
  background: rgb(255 253 253 / 78%);
  background: rgb(255 253 253 / 78%);
  color: #fff;
  height: 334px;
} */

.abouTilr {
    color: #252525;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    padding-top: 110px;
    padding-left: 150px;
}

.contactGetTit {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
}

.contactFormbaCk {
    box-sizing: border-box;
    width: 705px;
    height: 318px;
    background: #F3F9FF;
    /* background: red; */
    border-radius: 12px;
}

.conTACfoRm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
    padding: 1.2rem;
}

.padriGHTCONTACT {
    padding-right: 25PX;
}

.InputDemoSeconTCt {
    width: 320px;
    height: 44px;
    border: 1px solid rgba(0, 74, 163, 0.3);
    border-radius: 24px;
    font-size: 15px;
}

.InputDemoSeconTCtmailfoRm {
    width: 645px;
    height: 44px;
    border: 1px solid rgba(0, 74, 163, 0.3);
    border-radius: 24px;
    font-size: 15px;
}

.InputDemoSeconTCtTextAREafoRm {
    width: 645px;
    height: 84px;
    border: 1px solid rgba(0, 74, 163, 0.3);
    border-radius: 20px;
    padding-bottom: 50px;
    font-size: 15px;
}

.InputDemoSeconTCtfoRM {
    padding: 5px 0px 8px 0px;
}

.mapouter {
    text-align: right;
    height: 100%;
    width: 80%;
    display: flex;    
}

.gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
    /* padding-left: 305px; */
}

/* contact end */

/* kams */

.kamsBaneerPic {
    background: url('./assests/kamsss.jpg');
    height: 334px;
    margin-top: 88px;
}

.kamsTitlE {
    color: #252525;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
}

.kamsTitlE2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 75px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
    margin-top: -35px;
}

.kamsTitlE3 {
    font-family: 'Playball', cursive;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #004AA3;
    margin-top: -20px;
}

.kamSPADD {
    padding-top: 5%;
}

.feauKAMs {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
}

.kamsFeua {
    padding-top: 2%;
}

.kamsFeua2 {
    padding-top: 6%;
}

.feauKAMsconTEnt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #585858;
}

.kafeauTEr {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 69px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
    height: 50px;
    margin-top: 5%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.kafeauTEr2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #585858;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.masatamana {
    height: 417px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.masatamana2 {
    height: 690px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.masterkAms_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
    padding-top: 2%;
    padding-left: 5%;
}

.masterkAms_title2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
    padding-top: 2%;
    padding-right: 13%;
}

.masterkAms_content {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #585858;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 10%;
}

.masterkAms_content2 {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #585858;
    padding-top: 2%;
}

.widthmasterImageandContent {
    width: 40%;
    height: 417px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
}

.widthmasterImageandContent2 {
    width: 40%;
    height: 690px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
}

.widthmasterImageandContent3 {
    width: 40%;
    height: 209px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
}


.masatamana3 {
    height: 209px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent4 {
    width: 40%;
    height: 241px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
}


.masatamana4 {
    height: 241px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent5 {
    width: 40%;
    height: 920px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
}


.masatamana5 {
    height: 920px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent6 {
    width: 40%;
    height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
}


.masatamana6 {
    height: 380px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent7 {
    width: 40%;
    height: 607px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
}


.masatamana7 {
    height: 607px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent8 {
    width: 40%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
}


.masatamana8 {
    height: 600px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent9 {
    width: 40%;
    height: 350px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
}


.masatamana9 {
    height: 350px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent10 {
    width: 40%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
}


.masatamana10 {
    height: 500px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent11 {
    width: 40%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 5%;
}


.masatamana11 {
    height: 500px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
}

.widthmasterImageandContent12 {
    width: 40%;
    height: 338px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 3%;
}


.masatamana12 {
    height: 338px;
    background: #F3F9FF;
    border-radius: 12px;
    margin-top: 5%;
    margin-bottom: 10%;
}

/* kams end */


/* srithar */

.contactMapbetween{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

/* .container{
    display: flex;
    justify-content: end;
} */



/* srithar */