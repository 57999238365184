  @import url('https://fonts.googleapis.com/css2?family=Stick+No+Bills:wght@700&display=swap');

  @import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');


  .abouTilr {
    color: #252525;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    line-height: 75px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    padding-top: 110px;
    padding-left: 150px;
  }

  .contactGetTit {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
  }

  .contactFormbaCk {
    box-sizing: border-box;
    width: 705px;
    height: 318px;
    background: #F3F9FF;
    /* background: red; */
    border-radius: 12px;
  }

  .conTACfoRm {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.005em;
    text-transform: capitalize;
    color: #252525;
    padding: 1.2rem;
  }

  .padriGHTCONTACT {
    padding-right: 25PX;
  }

  .InputDemoSeconTCt {
    width: 320px;
    height: 44px;
    border: 1px solid rgba(0, 74, 163, 0.3);
    border-radius: 24px;
    font-size: 15px;
  }

  .InputDemoSeconTCtmailfoRm {
    width: 645px;
    height: 44px;
    border: 1px solid rgba(0, 74, 163, 0.3);
    border-radius: 24px;
    font-size: 15px;
  }

  .InputDemoSeconTCtTextAREafoRm {
    width: 645px;
    height: 84px;
    border: 1px solid rgba(0, 74, 163, 0.3);
    border-radius: 20px;
    padding-bottom: 50px;
    font-size: 15px;
  }

  .InputDemoSeconTCtfoRM {
    padding: 5px 0px 8px 0px;
  }

  .mapouter {
    text-align: right;
    height: 100%;
    width: 100%;
  }

  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 100%;
    width: 100%;
  }

  /* contact end */

  .Modal {
    padding: 25px;
    padding-bottom: 50px;
  }

  .Back {
    background: rgba(255, 255, 255, 0.25);
    padding: 25px;
    border-radius: 25px;
    width: 90%;
    margin-left: 23px;
  }

  .Modal_Css {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #FFFFFF;
  }

  .modal-header {
    border-bottom: 0px !important;
  }

  .InputCss {
    box-sizing: border-box;
    width: 320px;
    background: #FFFFFF;
    border-radius: 24px;
    padding: 10px 15px;
    border: none;
  }

  .ButtonCss {
    box-sizing: border-box;
    width: 320px;
    background: #003677;
    border-radius: 24px;
    padding: 10px 15px;
    border: none;
    color: #FFFFFF;
    font-weight: 500;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 16px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .Back_Image {
    /* background:url('./assests/Back_Image.png');
    background-repeat: no-repeat; */
  }

  .modal-content {
    background: url('./assests/Back_Image.png') !important;
    background-repeat: no-repeat !important;
    border-radius: 45px !important;
    background-size: cover;
  }

  .css-13cymwt-control::after {
    width: 320px !important;
  }

  .Mtop {
    background: url('./assests/Rems_Image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: 5.6%;
    height: 334px;
  }

  .CS_Font {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 50px;
    color: #252525;
  }

  .Back_CS {
    padding: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
  }

  .Back_Font {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #004AA3;
    cursor: pointer;
  }

  .modal {
    z-index: 99999999 !important;
  }

  .containerRTL {
    direction: rtl;
  }

  .pageRTL {
    transform: translatex(100%);
    animation: slideIn 0.5s forwards;
  }

  @keyframes slideIn {
    100% {
      transform: translatex(0);
    }
  }

  .sideBox {
    width: 100%;
    height: auto;
    background: #F4F4F4;
    border-radius: 0px 0px 10px 10px;
  }

  .sideText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.005em;
    width: 100%;
    color: #252525;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding: 10px;
  }

  .sideText:hover {
    background-color: #0077ba;
    color: #fafafa;
  }


  .card {
    display: none;
  }

  .card.hovered {
    display: block;
  }

  .containerpies {
    --cui-gutter-x: 1.5rem;
    --cui-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--cui-gutter-x) * .5);
    padding-left: calc(var(--cui-gutter-x) * .5);
    margin-right: auto;
    margin-left: auto;
  }