.aboyuiohead {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #004AA3;

}

.aboyuioheadMian {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #252525;

}

.aboyuioheadMianSecf {
    width: 90%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #585858;

}

.aboLeadertitle {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    /* or 160% */

    /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;
    text-transform: capitalize;

    color: #252525;

}

.leadercontentaboU {

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    /* or 178% */

    /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
    leading-trim: both;
    text-edge: cap;

    color: #585858;

}

.widhtLeadrtiou {
    width: 70%;
}

.mdlogocss {
    margin-right: 100px;
}


/* sritharan */


.Column_chanage{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


/* sritharan */